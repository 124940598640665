import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Layout/AuthLayout/Header";
import AfipayDesc from "../components/Layout/AuthLayout/AfipayDesc";
import ImgVisa from "../assets/new/img/logo-visa.png";
import ImgMastercard from "../assets/new/img/logo-mastercard.png";
import ImgPermata from "../assets/new/img/logo-permata.png";
import ImgBca from "../assets/new/img/logo-bca.png";
import ImgGopay from "../assets/new/img/logo-gopay.png";
import ImgBsi from "../assets/new/img/logo-bsi.png";
import ImgJcb from "../assets/new/img/logo-jcb.png";
import ImgAmex from "../assets/new/img/logo-amex.png";
import Spinner from "../components/Spinner/index.js";
import PembayaranGopay from "./MetodePembayaran/PembayaranGopay";
import PembayaranKartuKredit from "./MetodePembayaran/PembayaranKartuKredit";
import PembayaranVa from "./MetodePembayaran/PembayaranVa";
import PembayaranVaBca from "./MetodePembayaran/PembayaranVaBca";
import PembayaranBsi from "./MetodePembayaran/PembayaranBsi";
import { asCurrency, asDate, separatorEach4Char } from "../utils/formatter";
import { encryptDecript } from "../helpers/encryptDecrypt";
import AutoDebitKartuKredit from "./MetodePembayaran/AutoDebitKartuKredit";
import AutoDebitBcaPrivateCard from "./MetodePembayaran/AutoDebitBcaPrivateCard";
import AutoDebitBca from "./MetodePembayaran/AutoDebitBca";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Luhn from "luhn";
import { actionSetSkdkkDownload } from "../store/auth/actions";
import {
  actionResetPayment,
  actionCheckPaymentValidation,
} from "../store/payment/actions";
import {
  actionCheckCurrency,
  actionResetCurrency,
} from "../store/currency/actions";
import { paymentSimulation, visiblePaymentVa } from "../utils/config";
import ImgInfo from "../assets/new/img/icons/info.png";
import { Link } from "react-router-dom";

const defaultPremi = 0;
const showBsi = false;

const MetodePembayaran = (props) => {
  let data = props?.history?.location?.data || null;
  const {
    polis,
    auth,
    actionSetSkdkkDownload,
    actionResetPayment,
    actionCheckCurrency,
    actionResetCurrency,
    actionCheckPaymentValidation,
    paymentValidation,
    currency,
  } = props;

  const [loading, setLoading] = useState(false);
  const [btnLanjut, setBtnLanjut] = useState(false);

  const [premi, setPremi] = useState(defaultPremi);

  const [opsi, setOpsi] = useState("Pembayaran");
  const [periode, setPeriode] = useState(null);
  const [selected, setSelected] = useState(null);
  const [ccAgreement, setCcAgreement] = useState(null);
  const prevState = useRef({ selected }).current;


  const [namaPemegangKartu, setNamaPemegangKartu] = useState("");
  const [nomorKartuKredit, setNomorKartuKredit] = useState("");
  const [nomorKartuKreditDisplay, setNomorKartuKreditDisplay] = useState("");
  const [bulanBerlakuKartuKredit, setBulanBerlakuKartuKredit] = useState("");
  const [tahunBerlakuKartuKredit, setTahunBerlakuKartuKredit] = useState("");
  const [cvvKartuKredit, setCvvKartuKredit] = useState("");
  const [tncKartuKredit, setTncKartuKredit] = useState(false);
  const [agreeKartuKredit, setAgreeKartuKredit] = useState("");
  const [saveKartuKredit, setSaveKartuKredit] = useState(false);
  const [tncGopay, setTncGopay] = useState(false);
  const [agreeGopay, setAgreeGopay] = useState(false);
  const [tncVa, setTncVa] = useState(false);
  const [tncVaBca, setTncVaBca] = useState(false);
  const [tncBsi, setTncBsi] = useState(false);

  const [autoNamaPemegangKartu, setAutoNamaPemegangKartu] = useState("");
  const [autoNomorKartuKredit, setAutoNomorKartuKredit] = useState("");
  const [autoNomorKartuKreditDisplay, setAutoNomorKartuKreditDisplay] =
    useState("");
  const [autoBulanBerlakuKartuKredit, setAutoBulanBerlakuKartuKredit] =
    useState("");
  const [autoTahunBerlakuKartuKredit, setAutoTahunBerlakuKartuKredit] =
    useState("");
  const [autoCvvKartuKredit, setAutoCvvKartuKredit] = useState("");
  const [autoTncKartuKredit, setAutoTncKartuKredit] = useState(false);
  const [autoAgreeKartuKredit, setAutoAgreeKartuKredit] = useState(false);
  const [autoNamaPemegangBcaPrivateCard, setAutoNamaPemegangBcaPrivateCard] =
    useState("");
  const [autoNomorBcaPrivateCard, setAutoNomorBcaPrivateCard] = useState("");
  const [autoNomorBcaPrivateCardDisplay, setAutoNomorBcaPrivateCardDisplay] =
    useState("");
  const [autoBulanBerlakuBcaPrivateCard, setAutoBulanBerlakuBcaPrivateCard] =
    useState("");
  const [autoTahunBerlakuBcaPrivateCard, setAutoTahunBerlakuBcaPrivateCard] =
    useState("");
  const [autoCvvBcaPrivateCard, setAutoCvvBcaPrivateCard] = useState("");
  const [autoTncBcaPrivateCard, setAutoTncBcaPrivateCard] = useState(false);
  const [autoSudahDownload, setAutoSudahDownload] = useState(
    auth?.downloadSkdkk
  );
  const [autoHarusDownload, setAutoHarusDownload] = useState(
    auth?.downloadSkdkk === true ? false : true
  );
  const [submitBcaPrivateCard, setSubmitBcaPrivateCard] = useState(false);
  const [autoNamaPemilikRekeningBca, setAutoNamaPemilikRekeningBca] =
    useState("");
  const [autoNomorRekeningBca, setAutoNomorRekeningBca] = useState("");
  const [autoNomorPonselPendaftaranBca, setAutoNomorPonselPendaftaranBca] =
    useState("");
  const [autoTncBca, setAutoTncBca] = useState(false);

  const [paymentBlocked, setPaymentBlocked] = useState(false);

  const [currencyCode, setCurrencyCode] = useState("Rp ");

  const [paymentType, setPaymentType] = useState("");
  const [additionalPremium, setAdditionalPremium] = useState(0);
  const [residualPremium, setResidualPremium] = useState(0);
  const [nominalBayar, setNominalBayar] = useState("");
  const [canSplit, setCanSplit] = useState(true);
  const refPaymentType = useRef(null);
  const [errorNominalPembayaran, setErrorNominalPembayaran] = useState("");
  const [splitExpired, setSplitExpired] = useState(false);
  const [mustPaidOnce, setMustPaidOnce] = useState(false);
  const [splitPayment, setSplitPayment] = useState(false);
  const [openAllPayment, setOpenAllPayment] = useState(false);

  const resetPayment = () => {
    actionResetPayment();
    localStorage.removeItem("AFIPAY01A13");
    localStorage.removeItem("AFIPAY01A14");
    localStorage.removeItem("AFIPAY01A15");
    localStorage.removeItem("AFIPAY01A16");
    localStorage.removeItem("AFIPAY01A25");
    localStorage.removeItem("AFIPAY01A26");
    localStorage.removeItem("AFIPAY01A28");
  };

  useEffect(() => {
    resetPayment();
    actionCheckPaymentValidation(polis);

    if (polis?.currency === "USD") {
      setCurrencyCode("US$ ");
      actionCheckCurrency();
    } else {
      setCurrencyCode("Rp ");
      actionResetCurrency();
    }
  }, []);

  useEffect(() => {
    if (
      paymentValidation?.init === true &&
      paymentValidation?.enablePayment === false
    ) {
      setPaymentBlocked(true);
    } else {
      setPaymentBlocked(false);
    }
  }, [paymentValidation?.init]);

  useEffect(() => {
    setOpsi(paymentValidation?.opsi || "Pembayaran");
  }, [paymentValidation?.opsi]);

  useEffect(() => {
    setSelected(paymentValidation?.selected || null);
  }, [paymentValidation?.selected]);

  useEffect(() => {
    if (polis?.currency === "USD" && currency?.success !== null) {
      if (currency?.success === false) {
        setPaymentBlocked(true);
      } else {
        setPaymentBlocked(false);
      }
    }
  }, [currency?.success]);

  useEffect(() => {
    reset();
  }, [opsi]);

  useEffect(() => {
    if (periode == 2) {
      setPremi(2 * defaultPremi);
      localStorage.setItem("AFIPAY01A20", encryptDecript(2, 1));
    } else {
      setPremi(defaultPremi);
      localStorage.setItem("AFIPAY01A20", encryptDecript(1, 1));
    }
  }, [periode]);

  useEffect(() => {
    if (selected === "PembayaranKartuKredit") {
      reset(false);

      if (
        auth?.polis?.isSinglePremium == false ||
          auth?.polis?.isSinglePremium == "false"
          ? true
          : false
      ) {
        onChangeAgree(true);
      }
    } else if (
      selected === "PembayaranVaBca" ||
      selected === "PembayaranVaPermata"
    ) {
      // logic pilih bca <> permata tidak direset
      // if (prevState?.selected !== "PembayaranVaBca" && prevState?.selected !== "PembayaranVaPermata") { }
      reset(false);
    } else if (selected === "PembayaranGopay") {
      reset(false);
    } else if (selected === "PembayaranBsi") {
      reset(false);
    } else if (selected === "AutoDebitKartuKredit") {
      reset(false);
    } else if (selected === "AutoDebitBcaPrivateCard") {
      reset(false);
    } else if (selected === "AutoDebitBca") {
      reset(false);
    }

    return () => {
      prevState.selected = selected;
    };
  }, [selected, prevState]);

  useEffect(() => {
    let btnLanjut = false;

    if (opsi === "Pembayaran") {
      if (selected === "PembayaranKartuKredit") {
        if (
          namaPemegangKartu &&
          nomorKartuKredit &&
          bulanBerlakuKartuKredit &&
          tahunBerlakuKartuKredit &&
          cvvKartuKredit &&
          tncKartuKredit === true
        ) {
          if (paymentType === "once") {
            if (ccAgreement === "ya" || ccAgreement === "tidak") {
              btnLanjut = true;
            }
          }

          if (paymentType === "partial") {
            if (
              (ccAgreement === "ya" || ccAgreement === "tidak") &&
              nominalBayar !== ""
            ) {
              btnLanjut = true;
            }
          }
        }
      } else if (
        selected === "PembayaranVaBca" ||
        selected === "PembayaranVaPermata"
      ) {
        if (tncVa === true) {
          btnLanjut = true;
        }
      } else if (selected === "PembayaranGopay") {
        if (tncGopay === true && agreeGopay === true) {
          btnLanjut = true;
        }
      } else if (selected === "PembayaranBsi") {
        if (tncBsi === true) {
          // btnLanjut = true
        }
      } else if (selected === "PembayaranVaBcaHostToHost") {
        if (tncVaBca === true) {
          btnLanjut = true
        }
      }
    } else if (opsi === "AutoDebit") {
      if (selected === "AutoDebitKartuKredit") {
        if (
          autoNamaPemegangKartu &&
          autoNomorKartuKredit &&
          autoBulanBerlakuKartuKredit &&
          autoTahunBerlakuKartuKredit &&
          autoCvvKartuKredit &&
          autoTncKartuKredit === true &&
          autoAgreeKartuKredit === true
        ) {
          btnLanjut = true;
        }
      } else if (selected === "AutoDebitBcaPrivateCard") {
        if (
          autoNamaPemegangBcaPrivateCard &&
          autoNomorBcaPrivateCard &&
          autoBulanBerlakuBcaPrivateCard &&
          autoTahunBerlakuBcaPrivateCard &&
          autoCvvBcaPrivateCard &&
          autoTncBcaPrivateCard === true
        ) {
          // && autoSudahDownload === true
          btnLanjut = true;
        }
      } else if (selected === "AutoDebitBca") {
        if (
          autoNamaPemilikRekeningBca &&
          autoNomorRekeningBca &&
          autoNomorPonselPendaftaranBca &&
          autoTncBca === true
        ) {
          btnLanjut = true;
        }
      }
    }

    setBtnLanjut(btnLanjut);
  }, [
    opsi,
    selected,
    namaPemegangKartu,
    nomorKartuKredit,
    bulanBerlakuKartuKredit,
    tahunBerlakuKartuKredit,
    cvvKartuKredit,
    tncKartuKredit,
    tncVa,
    tncVaBca,
    tncGopay,
    agreeGopay,
    tncBsi,
    autoNamaPemegangKartu,
    autoNomorKartuKredit,
    autoBulanBerlakuKartuKredit,
    autoTahunBerlakuKartuKredit,
    autoCvvKartuKredit,
    autoTncKartuKredit,
    autoAgreeKartuKredit,
    autoNamaPemegangBcaPrivateCard,
    autoNomorBcaPrivateCard,
    autoBulanBerlakuBcaPrivateCard,
    autoTahunBerlakuBcaPrivateCard,
    autoCvvBcaPrivateCard,
    autoTncBcaPrivateCard,
    autoSudahDownload,
    autoNamaPemilikRekeningBca,
    autoNomorRekeningBca,
    autoNomorPonselPendaftaranBca,
    autoTncBca,
    ccAgreement,
    nominalBayar,
    paymentType,
  ]);

  useEffect(() => {
    let addPremi = 0;

    if (polis?.firstPremium !== "") {
      addPremi = parseInt(polis?.premiumAmount) - parseInt(polis?.firstPremium);

      if (addPremi < 0) {
        addPremi = 0;
      }

      if (
        parseInt(polis?.firstPremium) -
        parseInt(polis?.splitPaymentDetail[0]?.paymentAmount) ===
        0
      ) {
        setOpenAllPayment(true);
      }
    }
    setAdditionalPremium(addPremi);

    if (parseInt(polis?.premiumAmount) < parseInt(polis?.minCanSplitPayment)) {
      setCanSplit(false);
    }

    if (polis?.isSplitExpired) {
      setSplitExpired(true);
    }

    if (polis?.isSplitPayment) {
      setSplitPayment(true);
    }
  }, []);

  useEffect(() => {
    if (openAllPayment) {
      setPaymentType("once");
      let totalPaid = 0;
      polis?.splitPaymentDetail?.forEach((element) => {
        totalPaid += element.paymentAmount;
      });
      let residuPremi = polis?.premiumAmount - totalPaid;
      setResidualPremium(residuPremi);
      if (residuPremi < 0) {
        setResidualPremium(0);
      }

      polis.optSplitPayment = true;
    } else {
      if (!paymentValidation?.splitPaymentCC) {
        setPaymentType("once");
        setResidualPremium(polis?.premiumAmount);
        polis.optSplitPayment = false;
      } else {
        setPaymentType("partial");
        let totalPaid = 0;
        polis?.splitPaymentDetail?.forEach((element) => {
          totalPaid += element.paymentAmount;
        });
        let residuPremi = polis?.premiumAmount - totalPaid;
        setResidualPremium(residuPremi);
        if (residuPremi < 0) {
          setResidualPremium(0);
        }

        polis.optSplitPayment = true;
        polis.due_date = polis?.policyDueDt;
      }
    }
  }, [openAllPayment]);

  useEffect(() => {
    if (paymentType === "once") {
      openAllPayment
        ? (polis.premium = residualPremium.toString())
        : (polis.premium = polis?.premiumAmount);
    }

    if (paymentType === "partial") {
      polis.premium = nominalBayar
        .replace("Rp ", "")
        .replaceAll(".", "")
        .trim();
    }
  }, [nominalBayar, paymentType]);

  useEffect(() => {
    if (paymentType === "partial") {
      if (residualPremium < parseInt(polis?.minSplitPayment)) {
        setMustPaidOnce(true);
        setNominalBayar("Rp " + asCurrency(residualPremium));

        let inputField = document.getElementById("nominalPembayaran");
        if (inputField) inputField.setAttribute("disabled", true);
      }
    }
  }, [residualPremium]);

  // useEffect(() => {
  //     if (agreeKartuKredit === true || saveKartuKredit === true) {
  //         setSaveKartuKredit(true)
  //         setAgreeKartuKredit(true)
  //     }
  //     else if (agreeKartuKredit === false || saveKartuKredit === false) {
  //         setSaveKartuKredit(false)
  //         setAgreeKartuKredit(false)
  //     }
  // }, [agreeKartuKredit, saveKartuKredit])

  const onChangeAgree = (val) => {
    if (val === true) {
      setSaveKartuKredit(true);
      setAgreeKartuKredit(true);
    } else {
      setSaveKartuKredit(false);
      setAgreeKartuKredit(false);
    }
  };

  const onAgreeChange = (val) => {
    setCcAgreement(val);
    if (val === "ya") {
      setSaveKartuKredit(true);
      setAgreeKartuKredit(true);
    } else {
      setSaveKartuKredit(false);
      setAgreeKartuKredit(false);
    }
  };

  const onChangeSave = (val) => {
    if (val === true) {
      setSaveKartuKredit(true);
      setAgreeKartuKredit(true);
    } else {
      setSaveKartuKredit(false);
      setAgreeKartuKredit(false);
    }
  };

  const [errorNomorkartuKredit, setErrorNomorKartuKredit] = useState(null);
  const [errorBulanBerlakuKartuKredit, setErrorBulanBerlakuKartuKredit] =
    useState(null);
  const [errorTahunBerlakuKartuKredit, setErrorTahunBerlakuKartuKredit] =
    useState(null);
  const [errorCvvKartuKredit, setErrorCvvKartuKredit] = useState(null);
  const refPayment = useRef(null);

  const validatePembayaranKartuKredit = () => {
    setErrorNomorKartuKredit(null);
    setErrorBulanBerlakuKartuKredit(null);
    setErrorTahunBerlakuKartuKredit(null);
    setErrorCvvKartuKredit(null);

    let hasError = false;

    if (!Luhn.validate(nomorKartuKredit.trim())) {
      setErrorNomorKartuKredit("* Nomor Kartu tidak valid.");
      hasError = true;
    }

    let regexMonth = /^[0-9]+$/;
    if (
      !regexMonth.test(bulanBerlakuKartuKredit) ||
      parseInt(bulanBerlakuKartuKredit) === "NaN" ||
      isNaN(parseInt(bulanBerlakuKartuKredit)) ||
      parseInt(bulanBerlakuKartuKredit) < 1 ||
      parseInt(bulanBerlakuKartuKredit) > 12
    ) {
      setErrorBulanBerlakuKartuKredit("* Bulan Kadaluarsa tidak valid.");
      hasError = true;
    }

    let regexYear = /^[0-9]+$/;
    if (
      !regexYear.test(tahunBerlakuKartuKredit) ||
      parseInt(tahunBerlakuKartuKredit) === "NaN" ||
      isNaN(parseInt(tahunBerlakuKartuKredit))
    ) {
      setErrorTahunBerlakuKartuKredit("* Tahun Kadaluarsa tidak valid.");
      hasError = true;
    } else {
      let sYear = new Date().getFullYear().toString().substr(-2);
      if (parseInt(tahunBerlakuKartuKredit) - parseInt(sYear) < 0) {
        setErrorTahunBerlakuKartuKredit("* Tahun Kadaluarsa tidak valid.");
        hasError = true;
      } else if (parseInt(tahunBerlakuKartuKredit) - parseInt(sYear) === 0) {
        let sMonth = new Date().getMonth().toString();
        if (parseInt(bulanBerlakuKartuKredit) - 1 < parseInt(sMonth)) {
          setErrorBulanBerlakuKartuKredit("* Bulan Kadaluarsa tidak valid.");
          hasError = true;
        }
      }
    }

    let regexCvv = /^[0-9]+$/;
    if (
      !regexCvv.test(cvvKartuKredit) ||
      parseInt(cvvKartuKredit) === "NaN" ||
      isNaN(parseInt(cvvKartuKredit)) ||
      cvvKartuKredit.length < 3 ||
      cvvKartuKredit.length > 4
    ) {
      setErrorCvvKartuKredit("* CVV tidak valid.");
      hasError = true;
    }

    if (hasError === true) {
      refPayment.current.scrollIntoView();
      return false;
    }

    if (paymentType === "partial") {
      if (!mustPaidOnce) {
        if (
          parseInt(nominalBayar.replace("Rp ", "").replaceAll(".", "").trim()) <
          parseInt(polis?.minSplitPayment)
        ) {
          setErrorNominalPembayaran(
            "* Minimum nominal pembayaran tidak valid."
          );
          refPaymentType.current.scrollIntoView();
          return false;
        }
      }

      if (
        parseInt(nominalBayar.replace("Rp ", "").replaceAll(".", "").trim()) >
        parseInt(residualPremium)
      ) {
        setErrorNominalPembayaran(
          "* Nominal pembayaran melebihi sisa yang harus dibayar."
        );
        refPaymentType.current.scrollIntoView();
        return false;
      }
    }

    return true;
  };

  const [errorAutoNamaPemilikKartuKredit, setErrorAutoNamaPemilikKartuKredit] =
    useState(null);
  const [errorAutoNomorkartuKredit, setErrorAutoNomorKartuKredit] =
    useState(null);
  const [errorNamaPemilikKartuKredit, setErrorNamaPemilikKartuKredit] =
    useState(null);
  const [
    errorAutoBulanBerlakuKartuKredit,
    setErrorAutoBulanBerlakuKartuKredit,
  ] = useState(null);
  const [
    errorAutoTahunBerlakuKartuKredit,
    setErrorAutoTahunBerlakuKartuKredit,
  ] = useState(null);
  const [errorAutoCvvKartuKredit, setErrorAutoCvvKartuKredit] = useState(null);

  const validateAutoDebitKartuKredit = () => {
    setErrorAutoNomorKartuKredit(null);
    setErrorAutoBulanBerlakuKartuKredit(null);
    setErrorAutoTahunBerlakuKartuKredit(null);
    setErrorAutoNamaPemilikKartuKredit(null);
    setErrorAutoCvvKartuKredit(null);

    let hasError = false;

    let regexpemilik = /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/;
    if (
      !regexpemilik.test(autoNamaPemegangKartu) &&
      autoNamaPemegangKartu.trim() !== ""
    ) {
      setErrorAutoNamaPemilikKartuKredit("* Nama Pemilik kartu tidak valid.");
      hasError = true;
    }

    if (!Luhn.validate(autoNomorKartuKredit.trim())) {
      setErrorAutoNomorKartuKredit("* Nomor Kartu tidak valid.");
      hasError = true;
    }

    if (
      !regexpemilik.test(namaPemegangKartu) &&
      namaPemegangKartu.trim() !== ""
    ) {
      setErrorNamaPemilikKartuKredit("* Nama Pemilik kartu tidak valid.");
      hasError = true;
    }

    let regexMonth = /^[0-9]+$/;
    if (
      !regexMonth.test(autoBulanBerlakuKartuKredit) ||
      parseInt(autoBulanBerlakuKartuKredit) === "NaN" ||
      isNaN(parseInt(autoBulanBerlakuKartuKredit)) ||
      parseInt(autoBulanBerlakuKartuKredit) < 1 ||
      parseInt(autoBulanBerlakuKartuKredit) > 12
    ) {
      setErrorAutoBulanBerlakuKartuKredit("* Bulan Kadaluarsa tidak valid.");
      hasError = true;
    }

    let regexYear = /^[0-9]+$/;
    if (
      !regexYear.test(autoBulanBerlakuKartuKredit) ||
      parseInt(autoTahunBerlakuKartuKredit) === "NaN" ||
      isNaN(parseInt(autoTahunBerlakuKartuKredit))
    ) {
      setErrorAutoTahunBerlakuKartuKredit("* Tahun Kadaluarsa tidak valid.");
      hasError = true;
    } else {
      let sYear = new Date().getFullYear().toString().substr(-2);
      if (parseInt(autoTahunBerlakuKartuKredit) - parseInt(sYear) < 0) {
        setErrorAutoTahunBerlakuKartuKredit("* Tahun Kadaluarsa tidak valid.");
        hasError = true;
      } else if (
        parseInt(autoTahunBerlakuKartuKredit) - parseInt(sYear) ===
        0
      ) {
        let sMonth = new Date().getMonth().toString();
        if (parseInt(autoBulanBerlakuKartuKredit) - 1 < parseInt(sMonth)) {
          setErrorAutoBulanBerlakuKartuKredit(
            "* Bulan Kadaluarsa tidak valid."
          );
          hasError = true;
        }
      }
    }

    let regexCvv = /^[0-9]+$/;
    if (
      !regexCvv.test(autoCvvKartuKredit) ||
      parseInt(autoCvvKartuKredit) === "NaN" ||
      isNaN(parseInt(autoCvvKartuKredit)) ||
      autoCvvKartuKredit.length < 3 ||
      autoCvvKartuKredit.length > 4
    ) {
      setErrorAutoCvvKartuKredit("* CVV tidak valid.");
      hasError = true;
    }

    if (hasError === true) {
      refPayment.current.scrollIntoView();
      return false;
    }

    return true;
  };

  const [
    errorAutoNamaPemilikBcaPrivateCard,
    setErrorAutoNamaPemilikBcaPrivateCard,
  ] = useState(null);
  const [errorAutoNomorBcaPrivateCard, setErrorAutoNomorBcaPrivateCard] =
    useState(null);
  const [
    errorAutoBulanBerlakuBcaPrivateCard,
    setErrorAutoBulanBerlakuBcaPrivateCard,
  ] = useState(null);
  const [
    errorAutoTahunBerlakuBcaPrivateCard,
    setErrorAutoTahunBerlakuBcaPrivateCard,
  ] = useState(null);
  const [errorAutoCvvBcaPrivateCard, setErrorAutoCvvBcaPrivateCard] =
    useState(null);

  const validateAutoDebitBcaPrivateCard = () => {
    setErrorAutoNomorBcaPrivateCard(null);
    setErrorAutoBulanBerlakuBcaPrivateCard(null);
    setErrorAutoTahunBerlakuBcaPrivateCard(null);
    setErrorAutoNamaPemilikBcaPrivateCard(null);
    setErrorAutoCvvBcaPrivateCard(null);

    setSubmitBcaPrivateCard(true);

    let hasError = false;

    let regexpemilik = /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/;
    if (
      !regexpemilik.test(autoNamaPemegangBcaPrivateCard) &&
      autoNamaPemegangBcaPrivateCard.trim() !== ""
    ) {
      setErrorAutoNamaPemilikBcaPrivateCard(
        "* Nama Pemilik kartu tidak valid."
      );
      hasError = true;
    }

    if (!Luhn.validate(autoNomorBcaPrivateCard.trim())) {
      setErrorAutoNomorBcaPrivateCard("* Nomor Kartu tidak valid.");
      hasError = true;
    }

    let regexMonth = /^[0-9]+$/;
    if (
      !regexMonth.test(autoBulanBerlakuBcaPrivateCard) ||
      parseInt(autoBulanBerlakuBcaPrivateCard) === "NaN" ||
      isNaN(parseInt(autoBulanBerlakuBcaPrivateCard)) ||
      parseInt(autoBulanBerlakuBcaPrivateCard) < 1 ||
      parseInt(autoBulanBerlakuBcaPrivateCard) > 12
    ) {
      setErrorAutoBulanBerlakuBcaPrivateCard("* Bulan Kadaluarsa tidak valid.");
      hasError = true;
    }

    let regexYear = /^[0-9]+$/;
    if (
      !regexYear.test(autoBulanBerlakuBcaPrivateCard) ||
      parseInt(autoTahunBerlakuBcaPrivateCard) === "NaN" ||
      isNaN(parseInt(autoTahunBerlakuBcaPrivateCard))
    ) {
      setErrorAutoTahunBerlakuBcaPrivateCard("* Tahun Kadaluarsa tidak valid.");
      hasError = true;
    } else {
      let sYear = new Date().getFullYear().toString().substr(-2);
      if (parseInt(autoTahunBerlakuBcaPrivateCard) - parseInt(sYear) < 0) {
        setErrorAutoTahunBerlakuBcaPrivateCard(
          "* Tahun Kadaluarsa tidak valid."
        );
        hasError = true;
      } else if (
        parseInt(autoTahunBerlakuBcaPrivateCard) - parseInt(sYear) ===
        0
      ) {
        let sMonth = new Date().getMonth().toString();
        if (parseInt(autoBulanBerlakuBcaPrivateCard) - 1 < parseInt(sMonth)) {
          setErrorAutoBulanBerlakuBcaPrivateCard(
            "* Bulan Kadaluarsa tidak valid."
          );
          hasError = true;
        }
      }
    }

    let regexCvv = /^[0-9]+$/;
    if (
      !regexCvv.test(autoCvvBcaPrivateCard) ||
      parseInt(autoCvvBcaPrivateCard) === "NaN" ||
      isNaN(parseInt(autoCvvBcaPrivateCard)) ||
      autoCvvBcaPrivateCard.length < 3 ||
      autoCvvBcaPrivateCard.length > 4
    ) {
      setErrorAutoCvvBcaPrivateCard("* CVV tidak valid.");
      hasError = true;
    }

    if (autoSudahDownload === false) {
      setAutoHarusDownload(true);
      hasError = true;
    }

    if (hasError === true) {
      refPayment.current.scrollIntoView();
      return false;
    }

    return true;
  };

  const onSubmit = () => {
    setLoading(true);

    if (selected === "PembayaranKartuKredit") {
      if (validatePembayaranKartuKredit() === true) {
        resetPayment();
        props.history.push({
          pathname: process.env.REACT_APP_SUBDIR + "/pembayaran-kartu",
          data: {
            ...data,
            namaPemegangKartu: namaPemegangKartu,
            nomorKartuKredit: nomorKartuKredit,
            bulanBerlakuKartuKredit: bulanBerlakuKartuKredit,
            tahunBerlakuKartuKredit: tahunBerlakuKartuKredit,
            cvvKartuKredit: cvvKartuKredit,
            saveKartuKredit: saveKartuKredit,
          },
        });
      }
    } else if (
      selected === "PembayaranVaBca" ||
      selected === "PembayaranVaPermata"
    ) {
      resetPayment();
      props.history.push({
        pathname: process.env.REACT_APP_SUBDIR + "/pembayaran-va",
        data: {
          ...data,
          bank:
            selected === "PembayaranVaBca"
              ? "BCA"
              : selected === "PembayaranVaPermata"
                ? "Permata"
                : null,
        },
      });
    } else if (
      selected === "PembayaranVaBcaHostToHost" 
    ) {
      resetPayment();
      props.history.push({
        pathname: process.env.REACT_APP_SUBDIR + "/pembayaran-va-bca",
        data: {
          ...data
        },
      });
    }
    else if (selected === "PembayaranGopay") {
      resetPayment();
      props.history.push({
        pathname: process.env.REACT_APP_SUBDIR + "/pembayaran-gopay",
        data: {
          ...data,
        },
      });
    } else if (selected === "AutoDebitBca") {
      resetPayment();
      props.history.push({
        pathname: process.env.REACT_APP_SUBDIR + "/auto-debit-bca",
        data: {
          ...data,
          card_owner: autoNamaPemilikRekeningBca,
          card_number: autoNomorRekeningBca,
          phone_number: autoNomorPonselPendaftaranBca,
          saveCard: autoTncBca,
        },
      });
    } else if (selected === "AutoDebitKartuKredit") {
      if (validateAutoDebitKartuKredit() === true) {
        resetPayment();
        props.history.push({
          pathname: process.env.REACT_APP_SUBDIR + "/auto-debit-kartu-kredit",
          data: {
            ...data,
            card_owner: autoNamaPemegangKartu,
            card_number: autoNomorKartuKredit,
            card_exp_month: autoBulanBerlakuKartuKredit,
            card_exp_year: autoTahunBerlakuKartuKredit,
            card_cvv: autoCvvKartuKredit,
            saveCard: autoAgreeKartuKredit,
          },
        });
      }
    } else if (selected === "AutoDebitBcaPrivateCard") {
      if (validateAutoDebitBcaPrivateCard() === true) {
        resetPayment();
        props.history.push({
          pathname:
            process.env.REACT_APP_SUBDIR + "/auto-debit-bca-private-card",
          data: {
            ...data,
            card_owner: autoNamaPemegangBcaPrivateCard,
            card_number: autoNomorBcaPrivateCard,
            card_exp_month: autoBulanBerlakuBcaPrivateCard,
            card_exp_year: autoTahunBerlakuBcaPrivateCard,
            card_cvv: autoCvvBcaPrivateCard,
          },
        });
      }
    }
    setLoading(false);
  };

  const changeNomorKartuKredit = (val) => {
    if (val) {
      val = val
        ?.replace(/[^0-9\s]/g, "")
        ?.replace(/\s/g, "")
        ?.substr(0, 16);
      val = separatorEach4Char(val);
    }

    setNomorKartuKredit(val?.replace(/\s/g, ""));
    setNomorKartuKreditDisplay(val);
  };

  const changeAutoNomorKartuKredit = (val) => {
    if (val) {
      val = val
        ?.replace(/[^0-9\s]/g, "")
        ?.replace(/\s/g, "")
        ?.substr(0, 16);
      val = separatorEach4Char(val);
    }

    setAutoNomorKartuKredit(val?.replace(/\s/g, ""));
    setAutoNomorKartuKreditDisplay(val);
  };

  const changeAutoNomorBcaPrivateCard = (val) => {
    if (val) {
      val = val
        ?.replace(/[^0-9\s]/g, "")
        ?.replace(/\s/g, "")
        ?.substr(0, 16);
      val = separatorEach4Char(val);
    }

    setAutoNomorBcaPrivateCard(val?.replace(/\s/g, ""));
    setAutoNomorBcaPrivateCardDisplay(val);
  };

  const reset = (resetSelected = true) => {
    if (resetSelected === true) {
      setSelected(null);
    }
    setPeriode(null);
    setNamaPemegangKartu("");
    setNomorKartuKredit("");
    setNomorKartuKreditDisplay("");
    setBulanBerlakuKartuKredit("");
    setTahunBerlakuKartuKredit("");
    setCvvKartuKredit("");
    setTncKartuKredit(false);
    setAgreeKartuKredit("");
    setTncVa(false);
    setTncVaBca(false);
    setTncGopay(false);
    setAgreeGopay(false);
    setTncBsi(false);

    setSaveKartuKredit(false);

    setAutoNamaPemegangKartu("");
    setAutoNomorKartuKredit("");
    setAutoNomorKartuKreditDisplay("");
    setAutoBulanBerlakuKartuKredit("");
    setAutoTahunBerlakuKartuKredit("");
    setAutoCvvKartuKredit("");
    setAutoTncKartuKredit(false);
    setAutoAgreeKartuKredit(false);
    setAutoNamaPemegangBcaPrivateCard("");
    setAutoNomorBcaPrivateCard("");
    setAutoNomorBcaPrivateCardDisplay("");
    setAutoBulanBerlakuBcaPrivateCard("");
    setAutoTahunBerlakuBcaPrivateCard("");
    setAutoCvvBcaPrivateCard("");
    setAutoTncBcaPrivateCard(false);
    setAutoNamaPemilikRekeningBca("");
    setAutoNomorRekeningBca("");
    setAutoNomorPonselPendaftaranBca("");
    setAutoTncBca(false);

    setErrorNomorKartuKredit(null);
    setErrorBulanBerlakuKartuKredit(null);
    setErrorTahunBerlakuKartuKredit(null);
    setErrorCvvKartuKredit(null);

    setErrorAutoNomorKartuKredit(null);
    setErrorAutoBulanBerlakuKartuKredit(null);
    setErrorAutoTahunBerlakuKartuKredit(null);
    setErrorAutoNamaPemilikKartuKredit(null);
    setErrorAutoCvvKartuKredit(null);

    setErrorAutoNomorBcaPrivateCard(null);
    setErrorAutoBulanBerlakuBcaPrivateCard(null);
    setErrorAutoTahunBerlakuBcaPrivateCard(null);
    setErrorAutoNamaPemilikBcaPrivateCard(null);
    setErrorAutoCvvBcaPrivateCard(null);

    setCcAgreement(null);
  };

  const download = (e) => {
    e.preventDefault();
    setAutoSudahDownload(true);
    setAutoHarusDownload(false);
    actionSetSkdkkDownload(true);
    window.open(
      process.env.REACT_APP_BASEREF +
      "/assets/docs/Surat-Kuasa-Debet-Kartu-Kredit-AXA-Financial-Indonesia.pdf",
      "_blank"
    );
  };

  const onChangeCurrency = (e) => {
    let val = e.target.value;
    let prefix = "Rp ";
    var number_string = val.replace(/[^,\d]/g, "").toString(),
      split = number_string.split(","),
      rest = split[0].length % 3,
      rupiah = split[0].substr(0, rest),
      thousand = split[0].substr(rest).match(/\d{3}/gi);

    if (thousand) {
      let separator = rest ? "." : "";
      rupiah += separator + thousand.join(".");
    }

    rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
    setNominalBayar(
      prefix == undefined ? rupiah : rupiah ? "Rp " + rupiah : ""
    );
  };

  const viewInfoPremi = () => {
    return (
      <React.Fragment>
        {splitExpired ? (
          <div className="warning-box">
            <p>
              Nasabah Yth, pembayaran Anda telah melewati batas waktu yang telah
              ditentukan. Silahkan hubungi pihak yang terkait apabila ada
              pertanyaan dan menunggu refund dari kami.
            </p>
          </div>
        ) : null}
        <div className="accordion pb-4" id="accordionInfoPremi">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelInfoPremi"
                aria-expanded="true"
                aria-controls="panelInfoPremi"
              >
                <strong>Informasi Premi</strong>
              </button>
            </h2>
            <div
              id="panelInfoPremi"
              className="accordion-collapse collapse show"
            >
              <div className="accordion-body">
                <div className="row justify-content-between pb-2">
                  <div className="col-6 d-flex text-align-start">Premi</div>
                  <div
                    className="col-6 d-flex justify-content-end text-align-end"
                    style={{
                      color: "#00008f",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyCode}{" "}
                    {polis?.firstPremium === "" || additionalPremium === 0 
                      ? asCurrency(polis?.premiumAmount?.toString())
                      : asCurrency(parseInt(polis?.firstPremium)?.toString())}
                  </div>
                </div>
                <div className="row justify-content-between pb-2">
                  <div className="col-6 d-flex text-align-start">
                    Premi Tambahan
                  </div>
                  <div
                    className="col-6 d-flex justify-content-end text-align-end"
                    style={{
                      color: "#00008f",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyCode} {asCurrency(additionalPremium.toString())}
                  </div>
                </div>
                <div className="row justify-content-between pb-2">
                  <div className="col-6 d-flex text-align-start">
                    Total Premi
                  </div>
                  <div
                    className="col-6 d-flex justify-content-end text-align-end"
                    style={{
                      color: "#00008f",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyCode}{" "}
                    {asCurrency(polis?.premiumAmount?.toString())}
                  </div>
                </div>
                <div className="row justify-content-between pb-2">
                  <div className="col-6 d-flex text-align-start">
                    Premi Sudah Dibayar
                  </div>
                  <div
                    className="col-6 d-flex justify-content-end text-align-end"
                    style={{
                      color: "#00008f",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyCode}{" "}
                    {asCurrency(polis?.paidPremium?.toString())}
                  </div>
                </div>

                <hr className="divider" />
                <div className="row pb-2">
                  <div className="col-lg-12 d-flex text-align-start">
                    <strong>Premi Belum Dibayar</strong>
                  </div>
                </div>
                <div className="row justify-content-between pb-2">
                  <div className="col-6 d-flex text-align-start">
                    Sisa Premi
                  </div>
                  <div
                    className="col-6 d-flex justify-content-end text-align-end"
                    style={{
                      color: "#00008f",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyCode}{" "}
                    {residualPremium === 0
                      ? "-"
                      : asCurrency(residualPremium.toString())}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const viewInfoType = () => {
    return (
      <React.Fragment>
        <div className="subtitle-page fw-semibold pt-2 mb-4">
          Anda akan melakukan pembayaran sekaligus ?
        </div>
      </React.Fragment>
    );
  };

  const viewPaymentTypeOptions = () => {
    return (
      <React.Fragment>
        <ul
          className="nav nav-pills nav-justified"
          id="pills-tab-type"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <button
              className={
                "nav-link " +
                (paymentType === "once" ? "active " : " ") +
                (!openAllPayment && paymentValidation?.splitPaymentCC
                  ? "disabled"
                  : "")
              }
              id="pills-once-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-once"
              type="button"
              role="tab"
              aria-controls="pills-once"
              aria-selected="true"
              onClick={() => {
                setPaymentType("once");
                setSelected("");
                polis.optSplitPayment = false;
              }}
              style={
                !openAllPayment && paymentValidation?.splitPaymentCC
                  ? { color: "#999999" }
                  : {}
              }
            >
              Ya, Sekaligus
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={
                "nav-link " +
                (paymentType === "partial" ? "active " : " ") +
                (!openAllPayment &&
                  (paymentValidation?.splitPaymentCC || !canSplit)
                  ? "disabled"
                  : "")
              }
              id="pills-partial-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-partial"
              type="button"
              role="tab"
              aria-controls="pills-partial"
              aria-selected="false"
              onClick={() => {
                setPaymentType("partial");
                setSelected("");
                polis.optSplitPayment = true;
              }}
              style={
                !openAllPayment && paymentValidation?.splitPaymentCC
                  ? { backgroundColor: "#CCCCCC", color: "#757575" }
                  : {}
              }
            >
              Tidak, Sebagian
            </button>
          </li>
        </ul>
      </React.Fragment>
    );
  };

  const viewPaymentTypeDetail = () => {
    return (
      <React.Fragment>
        <div className="tab-content" id="pills-tabContent-type">
          <div
            className={
              "tab-pane fade " +
              (paymentType === "partial" ? "show active" : "")
            }
            id="pills-partial"
            role="tabpanel"
            aria-labelledby="pills-partial-tab"
            tabIndex="0"
          >
            <div className="row text-start">
              <div className="col-12 pt-2 pb-4">
                <div className="form-group input-icon-right">
                  <label htmlFor="nominalPembayaran">Nominal Pembayaran</label>
                  <span className="Rp">
                    <input
                      type="text"
                      name="nominalPembayaran"
                      id="nominalPembayaran"
                      maxLength="255"
                      size="255"
                      className="form-control"
                      value={nominalBayar}
                      onChange={(e) => onChangeCurrency(e)}
                      style={{
                        textAlign: "right",
                      }}
                    />
                    {errorNominalPembayaran ? (
                      <p className="form-tip form-alert">
                        <i>{errorNominalPembayaran}</i>
                      </p>
                    ) : null}
                  </span>
                </div>
              </div>
            </div>
            <div
              className="warning-box text-start"
              style={{
                backgroundColor: "#F5F5F5",
                marginBottom: "1rem",
              }}
            >
              <img height="25px" width="auto" src={ImgInfo} alt="info" />
              <strong className="px-3">Ketentuan Split Pembayaran:</strong>
              <ol
                type="1"
                style={{
                  marginRight: "2rem",
                  marginLeft: "2rem",
                }}
              >
                <li>
                  Minimum nominal pembayaran {currencyCode}{" "}
                  {asCurrency(polis?.minSplitPayment?.toString())}
                </li>
                <li>
                  Maximum nominal pembayaran adalah senilai Premi Belum Terbayar
                </li>
                <li>
                  Keseluruhan pembayaran harus diselesaikan dalam jangka waktu{" "}
                  {polis?.maxPeriodPaySplit} hari kalender
                </li>
              </ol>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const viewPaymentType = () => {
    return (
      <React.Fragment>
        <div className="row mb-3" ref={refPaymentType}>
          <div className="col-12">
            {viewPaymentTypeOptions()}
            {viewPaymentTypeDetail()}
          </div>
        </div>
      </React.Fragment>
    );
  };

  const viewInfo = () => {
    return (
      <React.Fragment>
        <div className="subtitle-page fw-semibold pt-2 mb-4">
        </div>
      </React.Fragment>
    );
  };

  const viewWarning = () => {
    return (
      <React.Fragment>

        {paymentSimulation === true ? (
          <div className="warning-box bg-light text-start">
            <h5>Simulasi Kartu Kredit / Debit</h5>
            <p>Nomor Kartu Kredit: 4811111111111114</p>
            <p>Bulan Kadaluarsa: 01</p>
            <p>Tahun Kadaluarsa: 25</p>
            <p>CVV: 1234</p>
          </div>
        ) : null}
      </React.Fragment>
    );
  };

  const viewPaymentMethodOptions = () => {
    return (
      <React.Fragment>
        <ul
          className="nav nav-pills nav-justified"
          id="pills-tab"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <button
              className={"nav-link " + "active"}
              id="pills-pembayaran-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-pembayaran"
              type="button"
              role="tab"
              aria-controls="pills-pembayaran"
              aria-selected="true"
              onClick={() => setOpsi("Pembayaran")}
              disabled={paymentValidation?.pembayaranPremi === false}
            >
              Pilih Metode Pembayaran
            </button>
          </li>
        </ul>
      </React.Fragment>
    );
  };

  const viewPaymentMethodDetail = () => {
    return (
      <React.Fragment>
        <div className="card">
          <div className="tab-content" id="pills-tabContent">
            <div
              className={
                "tab-pane fade " + (opsi === "Pembayaran" ? "show active" : "")
              }
              id="pills-pembayaran"
              role="tabpanel"
              aria-labelledby="pills-pembayaran-tab"
              tabIndex="0"
            >
              <div className="row m-0 px-2 text-start">
                <div className="col-12 pt-3 pb-5 px-3">
                  <p className="fw-semibold mb-2 color-dark">
                    <small>Kartu Kredit / Debit</small>
                  </p>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="pilihanPembayaran"
                      value="PembayaranKartuKredit"
                      onChange={() => setSelected("PembayaranKartuKredit")}
                      checked={selected === "PembayaranKartuKredit"}
                      disabled={paymentValidation?.pembayaranPremi === false}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="pembayaranKreditDebit"
                    >
                      <div className="d-flex gap-3">
                        <img
                          height="25px"
                          width="auto"
                          src={ImgVisa}
                          alt="visa"
                        />
                        <img
                          height="32px"
                          width="auto"
                          src={ImgMastercard}
                          alt="mastercard"
                        />
                        <img
                          height="28px"
                          width="auto"
                          src={ImgJcb}
                          alt="jcb"
                        />
                        <img
                          height="32px"
                          width="auto"
                          src={ImgAmex}
                          alt="amex"
                        />
                      </div>
                    </label>
                  </div>
                  <PembayaranKartuKredit
                    selected={selected === "PembayaranKartuKredit"}
                    nomorKartuKredit={nomorKartuKreditDisplay}
                    bulanBerlakuKartuKredit={bulanBerlakuKartuKredit}
                    tahunBerlakuKartuKredit={tahunBerlakuKartuKredit}
                    cvvKartuKredit={cvvKartuKredit}
                    tncKartuKredit={tncKartuKredit}
                    agreeKartuKredit={agreeKartuKredit}
                    saveKartuKredit={saveKartuKredit}
                    ccAgreement={ccAgreement}
                    setNomorKartuKredit={(e) =>
                      changeNomorKartuKredit(e.target?.value)
                    }
                    setNamaPemegangKartu={(e) => setNamaPemegangKartu(e.target?.value)}
                    setBulanBerlakuKartuKredit={(e) =>
                      setBulanBerlakuKartuKredit(e.target?.value)
                    }
                    setTahunBerlakuKartuKredit={(e) =>
                      setTahunBerlakuKartuKredit(e.target?.value)
                    }
                    setCvvKartuKredit={(e) =>
                      setCvvKartuKredit(e?.target?.value)
                    }
                    setTncKartuKredit={() => setTncKartuKredit(!tncKartuKredit)}
                    setTrueTncKartuKredit={() => setTncKartuKredit(true)}
                    setAgreeKartuKredit={() => onChangeAgree(!agreeKartuKredit)}
                    setSaveKartuKredit={() => onChangeSave(!saveKartuKredit)}
                    setCcAgreement={(e) => onAgreeChange(e.target?.value)}
                    errorNomorkartuKredit={errorNomorkartuKredit}
                    errorBulanBerlakuKartuKredit={errorBulanBerlakuKartuKredit}
                    errorTahunBerlakuKartuKredit={errorTahunBerlakuKartuKredit}
                    errorCvvKartuKredit={errorCvvKartuKredit}
                    isSinglePremium={
                      auth?.polis?.isSinglePremium == true ||
                        auth?.polis?.isSinglePremium == "true"
                        ? true
                        : false
                    }
                  />
                </div>
                <hr className="m-0" />
              </div>
              {visiblePaymentVa && paymentType !== "partial" ? (
                <div className="row m-0 px-2 text-start">
                  <div className="col-12 pt-3 pb-5 px-3" >
                    <p className="fw-semibold mb-2 color-dark">
                      <small>Virtual Akun Online</small>
                    </p>
                    <div className="form-check form-check-inline">
                      <input
                        style={{ marginTop: "0.5rem" }}
                        className="form-check-input"
                        type="radio"
                        name="pilihanPembayaran"
                        value="PembayaranVaPermata"
                        onChange={() => setSelected("PembayaranVaPermata")}
                        checked={selected === "PembayaranVaPermata"}
                        disabled={
                          !openAllPayment &&
                          (paymentValidation?.pembayaranPremi === false ||
                            paymentValidation?.splitPaymentCC === true ||
                            paymentType === "partial")
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pembayaranVAPermata"
                      >
                        <div className="d-flex gap-3">
                          <img
                            height="36px"
                            width="auto"
                            src={ImgPermata}
                            alt="permata"
                          />
                        </div>
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        style={{ marginTop: "0.5rem" }}
                        className="form-check-input"
                        type="radio"
                        name="pilihanPembayaran"
                        value="PembayaranVaBca"
                        onChange={() => setSelected("PembayaranVaBca")}
                        checked={selected === "PembayaranVaBca"}
                        disabled={
                          !openAllPayment &&
                          (paymentValidation?.pembayaranPremi === false ||
                            paymentValidation?.splitPaymentCC === true ||
                            paymentType === "partial")
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pembayaranVABca"
                      >
                        <div className="d-flex gap-3">
                          <img
                            height="34px"
                            width="auto"
                            src={ImgBca}
                            alt="bca"
                          />
                        </div>
                      </label>
                    </div>
                    <PembayaranVa
                      selected={
                        selected === "PembayaranVaPermata" ||
                        selected === "PembayaranVaBca"
                      }
                      tncVa={tncVa}
                      setTncVa={() => setTncVa(!tncVa)}
                      setTrueTncVa={() => setTncVa(true)}
                    />
                  </div>
                  <hr className="m-0" />
                </div>
              ) : null}
              {paymentType !== "partial" ? (
                <div className="row m-0 px-2 text-start">
                  <div className="col-12 pt-3 pb-4 px-3">
                    <p className="fw-semibold mb-2 color-dark">
                      <small>Virtual Akun Offline</small>
                    </p>
                    <div className="form-check form-check-inline">
                      <input
                        style={{ marginTop: "0.5rem" }}
                        className="form-check-input"
                        type="radio"
                        name="pilihanPembayaran"
                        value="PembayaranVaBcaHostToHost"
                        onChange={() => setSelected("PembayaranVaBcaHostToHost")}
                        checked={selected === "PembayaranVaBcaHostToHost"}
                        disabled={
                          !openAllPayment &&
                          (paymentValidation?.pembayaranPremi === false ||
                            paymentValidation?.splitPaymentCC === true ||
                            paymentType === "partial")
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pembayaranVAHostToHostBca"
                      >
                        <div className="d-flex gap-3">
                          <img
                            height="34px"
                            width="auto"
                            src={ImgBca}
                            alt="bca"
                          />
                        </div>
                      </label>
                    </div>
                    <PembayaranVaBca
                      selected={
                        selected === "PembayaranVaBcaHostToHost"
                      }
                      tncVaBca={tncVaBca}
                      setTncVaBca={() => setTncVaBca(!tncVaBca)}
                      setTrueTncVaBca={() => setTncVaBca(true)}
                    />
                  </div>
                  <hr className="m-0" />
                </div>
              ) : null}
              {paymentType !== "partial" ? (
                <div className="row m-0 px-2 text-start">
                  <div className="col-12 pt-3 pb-4 px-3">
                    <p className="fw-semibold mb-2 color-dark">
                      <small>Digital Wallet</small>
                    </p>
                    <div className="form-check form-check-inline">
                      <input
                        style={{ marginTop: "0.1rem" }}
                        className="form-check-input"
                        type="radio"
                        name="pilihanPembayaran"
                        value="PembayaranGopay"
                        onChange={() => {
                          setSelected("PembayaranGopay");
                        }}
                        checked={selected === "PembayaranGopay"}
                        disabled={
                          !openAllPayment &&
                          (paymentValidation?.pembayaranPremiGopay === false ||
                            paymentValidation?.splitPaymentCC === true ||
                            paymentType === "partial")
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pembayaranGopay"
                      >
                        <div className="d-flex gap-3">
                          <img
                            height="20px"
                            width="auto"
                            src={ImgGopay}
                            alt="gopay"
                          />
                        </div>
                      </label>
                    </div>
                    <PembayaranGopay
                      selected={selected === "PembayaranGopay"}
                      tncGopay={tncGopay}
                      agreeGopay={agreeGopay}
                      setTncGopay={() => setTncGopay(!tncGopay)}
                      setTrueTncGopay={() => setTncGopay(true)}
                      setAgreeGopay={() => setAgreeGopay(!agreeGopay)}
                    />
                  </div>
                  {showBsi === true ? <hr className="m-0" /> : null}
                </div>
              ) : null}

              {showBsi === true && paymentType !== "partial" ? (
                <div className="row m-0 px-2 text-start">
                  <div className="col-12 pt-3 pb-4 px-3">
                    <p className="fw-semibold mb-2 color-dark">
                      <small>Virtual Akun BSI</small>
                    </p>
                    <div className="form-check form-check-inline">
                      <input
                        style={{ marginTop: "0.8rem" }}
                        className="form-check-input"
                        type="radio"
                        name="pilihanPembayaran"
                        value="PembayaranBsi"
                        onChange={() => {
                          setSelected("PembayaranBsi");
                        }}
                        checked={selected === "PembayaranBsi"}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pembayaranVABsi"
                      >
                        <div className="d-flex gap-3">
                          <img
                            height="39px"
                            width="auto"
                            src={ImgBsi}
                            alt="bsi"
                          />
                        </div>
                      </label>
                    </div>
                    <PembayaranBsi
                      selected={selected === "PembayaranBsi"}
                      tncBsi={tncBsi}
                      setTncBsi={() => setTncBsi(!tncBsi)}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const viewPaymentMethod = () => {
    return (
      <React.Fragment>
        <div className="row mb-4" ref={refPayment}>
          <div className="col-12 mb-4">
            {viewPaymentMethodOptions()}
            {viewPaymentMethodDetail()}
          </div>
        </div>
      </React.Fragment>
    );
  };

  const viewDetailPembayaran = () => {
    return (
      <React.Fragment>
        {/* <p>Detail Pembayaran</p>
        <p className="card-text-label">Premi</p>
        <p className="highlight-text-md">
          {currencyCode} {asCurrency(polis?.premium?.toString())}
        </p>
        <p className="card-text-label">Tanggal Jatuh Tempo</p>
        <p className="highlight-text-red-md">
          {polis?.policyDueDt ? asDate(polis?.policyDueDt) : "-"}
        </p> */}

        <div className="d-flex">
          {btnLanjut === true && loading === false ? (
            <button
              onClick={(e) => onSubmit(e)}
              className="btn btn-primary px-5 col"
            >
              Lanjutkan
            </button>
          ) : (
            <button className="btn btn-primary px-5 col" disabled>
              Lanjutkan <Spinner visible={loading === true} />
            </button>
          )}
        </div>
        <div style={{ paddingBottom: "1rem" }}></div>

      </React.Fragment>
    );
  };

  const viewNoResidualPremium = () => {
    return (
      <React.Fragment>
        <div className="d-flex">
          <Link
            to={process.env.REACT_APP_SUBDIR + "/sign-out"}
            type="button"
            className="btn btn-primary px-5 col"
          >
            Keluar
          </Link>
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Header
        url={process.env.REACT_APP_SUBDIR + "/beranda"}
        urlLabel="Kembali"
        title={"Metode Pembayaran"}
      />

      <div className="container metode-pembayaran text-center pt-4 pb-1 mb-1">
        {paymentBlocked === true ? (
          <div className="warning-wrapper">
            {currency?.success === false && (
              <div className="warning-box warning-error mx-auto">
                <div className="d-block d-lg-flex align-items-center">
                  <span className="ic-alert m-auto me-lg-3"></span>
                  <p className="m-0">
                    Nasabah Yth, saat ini Kami membutuhkan waktu untuk
                    mengkonversi jumlah premi Anda. Silakan lakukan pengecekan
                    berkala pada jam operasional Senin-Jumat 10.00-22.00 WIB.
                  </p>
                </div>
              </div>
            )}
            {paymentValidation?.isDueDateMoreSixtyDays === true && (
              <div className="warning-box warning-error mx-auto">
                <div className="d-block d-lg-flex align-items-center">
                  <span className="ic-alert m-auto me-lg-3"></span>
                  <p className="m-0">
                    Saat ini Polis Anda belum Jatuh Tempo, mohon melakukan
                    kembali saat Jatuh Tempo Premi.
                  </p>
                </div>
              </div>
            )}

            {paymentValidation?.notificationSinglePremi === true && (
              <div className="warning-box warning-error mx-auto">
                <div className="d-block d-lg-flex align-items-center">
                  <span className="ic-alert m-auto me-lg-3"></span>
                  <p className="m-0">
                    Polis Anda tidak memiliki fitur premi lanjutan. Apabila Anda
                    ingin melakukan Top Up silakan kunjungi Customer Portal{" "}
                    <a
                      href={"https://myportal.axa.co.id/"}
                      target="_blank"
                      rel="noreferrer"
                      className="text-blue"
                    >
                      <b>https://myportal.axa.co.id/</b>
                    </a>{" "}
                    pada menu Rincian Polis Anda.
                  </p>
                </div>
              </div>
            )}

            {paymentValidation?.notificationLapse === true && (
              <div className="warning-box warning-error mx-auto">
                <div className="d-block d-lg-flex align-items-center">
                  <span className="ic-alert m-auto me-lg-3"></span>
                  <p className="m-0">
                    Polis Anda sedang tidak aktif, silakan melakukan
                    pengaktifan polis dengan melakukan pembayaran di Customer
                    Portal{" "}
                    <a
                      href={"https://myportal.axa.co.id/"}
                      target="_blank"
                      rel="noreferrer"
                      className="text-blue"
                    >
                      <b>https://myportal.axa.co.id/</b>
                    </a>{" "}
                    pada menu Rincian Polis Anda.
                  </p>
                </div>
              </div>
            )}

            {paymentValidation?.notificationBO === true && (
              <div className="warning-box warning-error mx-auto">
                <div className="d-block d-lg-flex align-items-center">
                  <span className="ic-alert m-auto me-lg-3"></span>
                  <p className="m-0">
                    Unduh dan lengkapi formulir Beneficial Owner pada link{" "}
                    <a
                      href={
                        process.env.REACT_APP_BASEREF +
                        "/assets/docs/Formulir-Beneficial-Owner-Perorangan-AFI-Sep2022.pdf"
                      }
                      target="_blank"
                      rel="noreferrer"
                      className="text-blue"
                    >
                      <b>berikut</b>
                    </a>{" "}
                    dan dikirimkan ke{" "}
                    <a
                      href={"mailto:customer@axa-financial.co.id"}
                      className="text-blue"
                    >
                      <b>customer@axa-financial.co.id</b>
                    </a>
                    .
                  </p>
                </div>
              </div>
            )}

            {paymentValidation?.notificationDBB === true && (
              <div className="warning-box warning-error mx-auto">
                <div className="d-block d-lg-flex align-items-center">
                  <span className="ic-alert m-auto me-lg-3"></span>
                  <p className="m-0">
                    Anda saat ini terdaftar dengan metode pembayaran Autodebit
                    Rekening, silakan menghubungi Customer Care AFI 1500 940
                    apabila Anda ingin melakukan perubahan menjadi Autodebit
                    Kartu Kredit.
                  </p>
                </div>
              </div>
            )}

            {currency?.success === true &&
              paymentValidation?.isDueDateMoreSixtyDays === false &&
              paymentValidation?.notificationSinglePremi === false &&
              paymentValidation?.notificationLapse === false &&
              paymentValidation?.notificationBO === false &&
              paymentValidation?.notificationDBB === false ? (
              <div className="warning-box warning-error mx-auto">
                <div className="d-block d-lg-flex align-items-center">
                  <span className="ic-alert d-block d-lg-none m-auto"></span>
                  <span className="ic-alert d-none d-lg-block me-3"></span>
                  <p className="m-0">Pembayaran tidak tersedia.</p>
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <>
            {viewInfoPremi()}
            {(canSplit || splitPayment) &&
              residualPremium !== 0 &&
              !splitExpired
              ? viewInfoType()
              : null}
            {(canSplit || splitPayment) &&
              residualPremium !== 0 &&
              !splitExpired
              ? viewPaymentType()
              : null}
            {residualPremium !== 0 && !splitExpired ? viewInfo() : null}
            {residualPremium !== 0 && !splitExpired ? viewWarning() : null}
            {residualPremium !== 0 && !splitExpired
              ? viewPaymentMethod()
              : null}
            {residualPremium !== 0 && !splitExpired
              ? viewDetailPembayaran()
              : null}
            {residualPremium === 0 || splitExpired
              ? viewNoResidualPremium()
              : null}
          </>
        )}

        <div style={{ paddingBottom: "1rem" }}></div>

        <AfipayDesc />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    polis: state.auth?.polis,
    paymentValidation: state.auth?.paymentValidation,
    currency: state.currency,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    actionSetSkdkkDownload,
    actionResetPayment,
    actionCheckPaymentValidation,
    actionCheckCurrency,
    actionResetCurrency,
  })(MetodePembayaran)
);
