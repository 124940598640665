import React, { useEffect, useState, useRef } from "react"
import SnK from "./SnK"

const PembayaranVaBca = ({ selected = false, tncVaBca, setTncVaBca, setTrueTncVaBca }) => {
    const [show, setShow] = useState("v-hidden")
    const [tncBcaClicked, seTncBcaClicked] = useState(false)
    const refTnc = useRef(null)

    useEffect(() => {
        if (selected === true) {
            setShow("v-hidden collapse-animate-lg")

            setTimeout(() => {
                setShow("collapse-animate-lg")
            }, 500)
        } else {
            setShow("v-hidden")
        }
    }, [selected])

    const onChangeTncBca = () => {
        if (tncBcaClicked === false) {
            refTnc.current.click()
        } else {
            setTncVaBca()
        }
    }

    const acceptTncBca = () => {
        setTncVaBca()
        seTncBcaClicked(true)
    }

    return <React.Fragment>
        <div className={"data-pembayaran " + show}>
            <div className="warning-box">
                <p>
                    Jaga Kerahasiaan data pribadi Anda, Jangan berikan data pribadi Anda
                    ke pihak lain.
                </p>
            </div>

            <div className="grey-box ketentuan-pembayaran">
                <div className="ps-3 mb-2">
                    <label className="form-check-label" htmlFor="agreeCard">
                        <small>
                            <strong>Catatan</strong> :
                        </small>
                    </label>
                </div>
                <ol className="ps-3">
                    <li>
                        Bukti Pembayaran Premi WAJIB dikirim ke email customer@axa-financial.co.id. subjek Email diisi [BUKTI BAYAR VA] No Polis - Nama Pemegang Polis.
                    </li>
                    <li>
                        Transaksi yang kami terima pada hari kerja sebelum pukul 16.00 WIB akan diproses di hari yang sama, dan transaksi diatas jam 16.00 WIB akan diproses pada hari kerja berikutnya.
                    </li>
                </ol>
                <div className="form-check mb-2">
                    <input className="form-check-input" type="checkbox" value="tncVA" id="tncVA" onChange={setTncVaBca} checked={tncVaBca === true} />
                    <label className="form-check-label" htmlFor="tncVA">
                        <small>
                            Saya setuju dan mengerti terhadap {" "}
                            <a ref={refTnc} href="." onClick={(e) => e.preventDefault()} data-bs-toggle="modal"
                                data-bs-target="#modalTncVaBca">Syarat dan Ketentuan</a>
                        </small>
                    </label>
                </div>
            </div>
        </div>

        {/** <SnK id={"modalTncVa"} acceptTnc={tncClicked ? null : acceptTnc} /> */}
        <SnK id={"modalTncVaBca"} acceptTnc={setTrueTncVaBca} opsi="vaBca" />
    </React.Fragment>
}

export default PembayaranVaBca