import React from "react"

const SnK = ({ id, acceptTnc = null, opsi = "Pembayaran" }) => {


   
    return <React.Fragment>
        <div className="modal fade" id={id} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
            aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-md modal-dialog-centered">
                <div className={"modal-content tnc"}>
                    <div className="modal-header">
                        <h5 className="modal-title">Syarat dan Ketentuan</h5>
                    </div>
                    <div className="modal-body pt-0 pb-3">

                    {
                            
                            opsi === "vaBca" ?
                            <ol className="ps-3">
                                <li>
                                	Mohon pastikan nominal premi yang diinput sudah sesuai dengan premi yang tertagih.
                                </li>
                                <li>
                                    Bukti Pembayaran Premi WAJIB dikirim ke email customer@axa-financial.co.id, subjek Email diisi [BUKTI BAYAR VA] No Polis - Nama Pemegang Polis.
                                </li>
                                <li>
                                    Transaksi akan diproses apabila dana yang dibayarkan sesuai dengan premi tertagih dan berhasil masuk ke rekening PT AXA Financial Indonesia.
                                </li>
                                <li>
                                    Transaksi yang kami terima pada hari kerja sebelum pukul 16.00 WIB akan diproses di hari yang sama, dan transaksi diatas jam 16.00 WIB akan diproses pada hari kerja berikutnya.
                                </li>
                                <li>
                                Pembayar wajib Pemegang Polis dan menggunakan akun atas nama Pemegang Polis. Bila Pembayar bukan Pemegang Polis atau menggunakan akun bukan milik Pemegang Polis, akun yang dapat digunakan HANYA akun dari keluarga inti Pemegang Polis (suami/istri/anak-orangtua/wali resmi). Pemilik Akun WAJIB mengisi dan menandatangani formulir Beneficial Owner disertai fotocopy kartu keluarga/akta nikah/surat perwalian/dokumen lainnya sebagaimana dipersyaratkan oleh PT AXA Financial Indonesia.
                                </li>
                                <li>
                                	Pembayaran premi dinyatakan sah setelah dana diterima di rekening PT AXA Financial Indonesia.
                                </li>
                                <li>
                                    Seluruh biaya yang timbul atas transaksi pembayaran menjadi tanggung jawab Nasabah sepenuhnya.
                                </li>
                                <li>
                                	PT AXA Financial Indonesia tidak bertanggung jawab atas penolakan otorisasi oleh bank penerbit.
                                </li>
                            </ol>
                            :null

                        }
                        {
                            opsi === "Pembayaran" ?
                                <ol className="ps-3">
                                    <li>
                                    Pemegang Polis wajib menggunakan kartu kredit atas nama Pemegang Polis. Bila menggunakan kartu kredit bukan milik Pemegang Polis, kartu kredit yang dapat digunakan HANYA akun rekening dari keluarga inti Pemegang Polis (suami/istri/anak-orangtua/wali resmi). Pemilik kartu kredit WAJIB mengisi dan menandatangani formulir Beneficial Owner disertai fotocopy kartu keluarga/akta nikah/surat perwalian/dokumen lainnya sebagaimana dipersyaratkan oleh PT AXA Financial Indonesia.
                                    </li>
                                    <li>
                                        Persetujuan ini juga termasuk persetujuan untuk pemberian kuasa yang tidak dapat dicabut kembali kepada PT AXA Financial Indonesia untuk melakukan penarikan dana dari kartu kredit/debit untuk pembayaran premi pertama dan premi lanjutan termasuk bila ada perubahan premi yang telah disetujui oleh PT AXA Financial Indonesia.
                                    </li>
                                    <li>
                                        Untuk proses pendaftaran Kartu Kredit, Anda akan didebet terlebih dahulu sebesar Rp 10.000. Setelah transaksi berhasil dana tersebut akan dikembalikan secara otomatis ke limit Kartu Kredit Anda.
                                    </li>
                                    <li>
                                        Pembayaran premi dinyatakan sah setelah dana diterima di rekening PT. AXA Financial Indonesia.
                                    </li>
                                    <li>
                                        PT AXA Financial Indonesia tidak bertanggung jawab atas penolakan otorisasi oleh bank penerbit.
                                    </li>
                                </ol>
                                : null
                        }

                        {
                            opsi === "AutoDebit" ?
                                <ol className="ps-3">
                                    <li>
                                    Pemegang Polis wajib menggunakan kartu kredit atas nama Pemegang Polis. Bila menggunakan kartu kredit bukan milik Pemegang Polis, kartu kredit yang dapat digunakan HANYA akun rekening dari keluarga inti Pemegang Polis (suami/istri/anak-orangtua/wali resmi). Pemilik kartu kredit WAJIB mengisi dan menandatangani formulir Beneficial Owner disertai fotocopy kartu keluarga/akta nikah/surat perwalian/dokumen lainnya sebagaimana dipersyaratkan oleh PT AXA Financial Indonesia.
                                    </li>
                                    <li>
                                        Persetujuan ini juga termasuk persetujuan untuk pemberian kuasa yang tidak dapat dicabut kembali kepada PT AXA Financial Indonesia untuk melakukan penarikan dana dari kartu kredit untuk pembayaran premi pertama dan premi lanjutan termasuk bila ada perubahan premi yang telah disetujui oleh PT AXA Financial Indonesia
                                    </li>
                                    <li>
                                        Untuk proses pendaftaran Kartu Kredit, Anda akan didebet terlebih dahulu sebesar Rp 10.000. Setelah transaksi berhasil dana tersebut akan dikembalikan secara otomatis ke limit Kartu Kredit Anda
                                    </li>
                                    <li>
                                        Pembayaran premi dinyatakan sah setelah dana diterima di rekening PT. AXA Financial Indonesia
                                    </li>
                                    <li>
                                        PT AXA Financial Indonesia tidak bertanggung jawab atas penolakan otorisasi oleh bank penerbit.
                                    </li>
                                </ol>
                                : null
                        }

                        {
                            opsi === "PembayaranNonCC" ?
                                <ol className="ps-3">
                                    <li>
                                    Pembayar wajib Pemegang Polis dan menggunakan akun atas nama Pemegang Polis. Bila Pembayar bukan Pemegang Polis atau menggunakan akun bukan milik Pemegang Polis, akun yang dapat digunakan HANYA akun dari keluarga inti Pemegang Polis (suami/istri/anak-orangtua/wali resmi). Pemilik Akun WAJIB mengisi dan menandatangani formulir Beneficial Owner disertai fotocopy kartu keluarga/akta nikah/surat perwalian/dokumen lainnya sebagaimana dipersyaratkan oleh PT AXA Financial Indonesia.
                                    </li>
                                    <li>
                                        Persetujuan ini juga termasuk persetujuan untuk pemberian kuasa yang tidak dapat dicabut kembali kepada PT AXA Financial Indonesia untuk melakukan penarikan dana dari akun untuk pembayaran premi pertama dan premi lanjutan termasuk bila ada perubahan premi yang telah disetujui oleh PT AXA Financial Indonesia.
                                    </li>
                                    <li>
                                        Pembayaran premi dinyatakan sah setelah dana diterima di rekening PT. AXA Financial Indonesia.
                                    </li>
                                    <li>
                                        PT AXA Financial Indonesia tidak bertanggung jawab atas penolakan otorisasi oleh bank penerbit.
                                    </li>
                                </ol>
                                : null
                                
                                


                        }

                        {
                            opsi === "AutodebitNonCC" ?
                                <ol className="ps-3">
                                    <li>
                                        Pembayar wajib Pemegang Polis dan menggunakan akun atas nama Pemegang Polis. Bila Pembayar bukan Pemegang Polis atau menggunakan akun bukan milik Pemegang Polis, akun yang dapat digunakan HANYA akun dari keluarga inti Pemegang Polis (suami/istri/anak orang tua/ wali resmi). Dan pemilik akun wajib menandatangani formulir Beneficial Owner disertai fotocopy kartu keluarga/akta nikah/surat perwalian.
                                    </li>
                                    <li>
                                        Persetujuan ini juga termasuk persetujuan untuk pemberian kuasa yang tidak dapat dicabut kembali kepada PT AXA Financial Indonesia untuk melakukan penarikan dana dari akun untuk pembayaran premi pertama dan premi lanjutan termasuk bila ada perubahan premi yang telah disetujui oleh PT AXA Financial Indonesia.
                                    </li>
                                    <li>
                                        Pembayaran premi dinyatakan sah setelah dana diterima di rekening PT. AXA Financial Indonesia.
                                    </li>
                                    <li>
                                        PT AXA Financial Indonesia tidak bertanggung jawab atas penolakan otorisasi oleh bank penerbit.
                                    </li>
                                </ol>
                                : null


                        }


                    </div>
                    <div className="modal-footer d-flex">
                        {
                            acceptTnc ?
                                <button type="button" className="btn btn-primary col" data-bs-dismiss="modal" onClick={acceptTnc}>Setuju</button>
                                : <button type="button" className="btn btn-primary col" data-bs-dismiss="modal">Setuju</button>
                        }

                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
}

export default SnK